import publicApi from "api/public"
import { LogoSrc } from "assets"
import Marquee from "components/Marquee"
import { supportedChainsConfig } from "constants/chains"
import { extensionId } from "constants/extension"
import { useAuth } from "contexts/Auth"
import { FC, useEffect, useState } from "react"
import { Outlet } from "react-router"
import { IBiggestPump } from "types/pumps"

const shortenAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`
}

const MainLayout: FC = () => {
  const { user, signInWithEthereum, authInProgress, signInWithPhantom } = useAuth()
  const chain = user?.uid.split(":")[1]
  const chainData = chain ? supportedChainsConfig.find(c => c.id === chain) : null

  const connectText = authInProgress ? "Connecting..." : user ? `${shortenAddress(user.uid.split(":")[2])}` : "Connect"
  const [biggestPumps, setBiggestPumps] = useState<IBiggestPump[]>()

  useEffect(() => {
    publicApi.getBiggestPumps().then(setBiggestPumps)
  }, [])

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div className="w-full py-2.5 bg-bg-green grow-0 h-[2.375rem] shrink-0 z-50 relative">
        <Marquee>
          {
            biggestPumps?.map(pump => (
              <div key={pump.pump} className="flex items-center gap-1 min-w-max">
                <img
                  src={pump.token.image.replace("cf-ipfs.com", "pump.mypinata.cloud")}
                  className="rounded-full object-cover w-[18px] h-[18px]"
                />
                <span className="text-xs text-[#202020] leading-none">
                  X{Math.floor(pump.multiplier)}
                </span>
                <span className="text-xs text-[#6D9D00] font-semibold leading-none">
                  {pump.token.name}
                </span>
              </div>
            ))
          }
        </Marquee>
      </div>
      <div className="grow flex flex-col w-full h-full overflow-hidden">
        {/*<div className="w-full h-24 shrink-0 bg-bg-green border-b border-b-border-primary flex items-center justify-between px-16">
          <div className="flex items-center gap-4">
            <img src={LogoSrc} className="h-14 w-14" />
            <h1 className="text-lg font-bold text-white">
              TrenchScout
            </h1>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                window.open(`https://chromewebstore.google.com/detail/${extensionId}`, "_blank")
              }}
              className="bg-white flex items-center
              hover:bg-[rgba(255,255,255,0.9)]
             text-black border border-white transition-colors text-base px-5 gap-2 py-3 rounded-lg"
            >
              Get Extension
            </button>
            <button
              onClick={signInWithPhantom}
              className="bg-bg-primary flex items-center
              hover:bg-[rgba(255,255,255,0.1)]
             text-white border border-border-primary transition-colors text-base px-5 gap-2 py-3 rounded-lg"
            >
              {/*<div dangerouslySetInnerHTML={{ __html: chainData?.svg || "" }} className="[&>svg]:w-8 [&>svg]:h-8" />}
              {connectText}
            </button>
          </div>
        </div>*/}
        <div className="grow w-full relative h-full">
          <div className="grow h-full w-full absolute z-[1]">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainLayout
