import { FC, PropsWithChildren } from "react"

interface props {
    className?: string
}

const Marquee: FC<PropsWithChildren<props>> = ({ children, className }) => {
  return (
    <div
      className={["w-full overflow-x-hidden relative flex items-center marquee", className].asClass}
    >
      <div
        className="mobile:min-w-max w-full flex justify-around gap-2 px-1"
      >
        {children}
      </div>
      <div
        className="mobile:min-w-max w-full flex justify-around gap-2 px-1"
      >
        {children}
      </div>
    </div>
  )
}

export default Marquee
