import { DogeSrc } from "assets"
import { TrendingDownIcon, TrendingOkIcon } from "assets/icons"
import { FC, useCallback, useMemo, useRef, useSyncExternalStore } from "react"

interface DataPoint {
  title: string
  value: number
  status: "ok" | "trending-down"
}

interface Props {
  dataPoints: DataPoint[]
}

const polygonPoints = (n: number, r: number, { cx, cy }: { cx: number; cy: number }) => {
  if (n < 3) return []

  const initialAngle = -Math.PI / 2

  const angles = Array(n)
    .fill(initialAngle)
    .map((initial, i) => initial + (2 * i * Math.PI) / n)

  const points = angles.map(angle => {
    const dx = Math.cos(angle) * r
    const dy = Math.sin(angle) * r

    return [cx + dx, cy + dy]
  })

  return points
}

const HexGraph: FC<Props> = ({ dataPoints }) => {
  const topRef = useRef<SVGSVGElement>(null)
  const maxR = 279
  const center = useMemo(() => ({ cx: maxR, cy: maxR }), [])
  const scalePoints = 5
  const scaleOffsets = Array(scalePoints + 1)
    .fill(null)
    .map((_, i) => (maxR * i) / scalePoints)
  const scaleLineEnds = polygonPoints(dataPoints.length, maxR, center)

  const valuePolygonPoints = useMemo(
    () => polygonPoints(dataPoints.length, maxR, center).map((max, i) => {
      const maxX = max[0]
      const maxY = max[1]
      const maxDx = maxX - center.cx
      const maxDy = maxY - center.cy
      const value = dataPoints[i].value
      const dx = (maxDx * (value * maxR / 100)) / maxR
      const dy = (maxDy * (value * maxR / 100)) / maxR

      return [center.cx + dx, center.cy + dy]
    }),
    [dataPoints, center],
  )
  const valuePointR = 7

  const hPadding = 45
  const vPadding = 74

  const minX = -hPadding
  const minY = -vPadding
  const w = 2 * (maxR + hPadding)
  const h = 2 * (maxR + vPadding)

  const isSafari = useMemo(() => {
    const userAgent = navigator.userAgent
    const chromeAgent = userAgent.indexOf("Chrome/") > -1 || userAgent.indexOf("Chromium/") > -1
    const safariAgent = userAgent.indexOf("Safari/") > -1

    return safariAgent && !chromeAgent
  }, [])

  const subscribeToResize = useCallback((subscribe: () => void) => {
    window.addEventListener("resize", subscribe)

    return () => window.removeEventListener("resize", subscribe)
  }, [])
  const getScale = useCallback(() => {
    if (!topRef.current) return 1
    if (isSafari) return topRef.current.clientHeight / h

    return 1
  }, [topRef, h, isSafari])

  const scale = useSyncExternalStore(subscribeToResize, getScale)

  return (
    <div
      className="w-full"
      style={{ aspectRatio: w/h }}
    >
      <svg
        ref={topRef}
        key={valuePolygonPoints.length}
        className="w-full"
        viewBox={[minX, minY, w, h].join(" ")}
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
      >
        <polygon
          points={valuePolygonPoints.map(point => point.join(",")).join(" ")}
          fill="#252325"
          stroke="none"
          className="opacity-0 animate-[fade-in_0.5s_ease-in_1s_1_normal_forwards]"
        />
        {scaleOffsets.map(r => (
          <polygon
            points={polygonPoints(dataPoints.length, r, center)
              .map(point => point.join(","))
              .join(" ")}
            fill="none"
            stroke="#453E54"
          />
        ))}
        {dataPoints.map((_, i) => (
          <line x1={maxR} y1={maxR} x2={scaleLineEnds[i][0]} y2={scaleLineEnds[i][1]} stroke="#453E54" />
        ))}
        <polygon
          points={valuePolygonPoints.map(point => point.join(",")).join(" ")}
          fill="none"
          stroke="#C3FF3A"
          className="opacity-0 animate-[fade-in_0.5s_ease-in_1s_1_normal_forwards]"
          strokeWidth={1}
        />
        {valuePolygonPoints.map(([cx, cy], i) => (
          <circle
            r={valuePointR}
            cx={cx}
            cy={cy}
            fill="#130B23"
            stroke="#C3FF3A"
            className="opacity-0 animate-[fade-in_0.5s_ease-in_1s_1_normal_forwards]"
            strokeWidth={1}
          />
        ))}
        <g
          className="animate-[float_1.5s_ease-in-out_0s_infinite]"
          x={center.cx - 89}
          y={center.cy - 86}
          width={179}
          height={172}
        >
          <image
            href={DogeSrc}
            x={center.cx - 89}
            y={center.cy - 86}
            width={179}
            height={172}
            className="opacity-0 animate-[fade-in_0.5s_ease-in_1.6s_1_normal_forwards]"
          />
        </g>
        {valuePolygonPoints.map(([cx, cy], i) => {
          const {
            title,
            value,
            status,
          } = dataPoints[i]
          const originalDx = cx - center.cx
          const originalDy = cy - center.cx
          const originalMagnitude = Math.sqrt(originalDx * originalDx + originalDy * originalDy)

          const unitX = originalDx / originalMagnitude
          const unitY = originalDy / originalMagnitude

          const dx = unitX * maxR
          const dy = unitY * maxR

          const tAxis = Math.abs(unitX) > Math.abs(unitY) ? "x" : "y"

          const tx = tAxis === "x"
            ? dx > 0
              ? "translateX(11px) translateY(-50%)"
              : "translateX(-100%) translateX(-11px) translateY(-50%)"
            : null
          const ty = tAxis === "y"
            ? dy > 0
              ? "translateY(13px) translateX(-50%)"
              : "translateY(-100%) translateY(-13px) translateX(-50%)"
            : null

          return (
            <g
              key={cx}
              x={dx + center.cx}
              y={dy + center.cy}
              width={1}
              height={1}
            >
              <foreignObject
                x={dx + center.cx}
                y={dy + center.cy}
                width={2}
                height={2}
                overflow="visible"
                style={{
                  fontSize: `${scale}rem`,
                  animationName: "fade-in-top",
                  animationDuration: "0.5s",
                  animationTimingFunction: "ease-in",
                  animationDelay: `${1.2 + i * 0.2}s`,
                  animationIterationCount: 1,
                  animationFillMode: "forwards",
                }}
                className="opacity-0"
              >
                <div
                  className="bg-[#84848433] w-max h-max fixed rounded-[0.75em] flex flex-col gap-[0.25em] backdrop-blur-lg
                 overflow-hidden blur-0"
                  style={{
                    ...(isSafari ? ({
                      // safari is retarded
                      left: (dx + center.cx) * scale,
                      top: (dy + center.cy) * scale,
                      transform: [
                        tx,
                        ty,
                        "translateX(22px)",
                        "translateY(36px)",
                      ].filter(Boolean).join(" "),
                    }) : ({ transform: [tx, ty].filter(Boolean).join(" ") })),
                    alignItems: tAxis === "x" ? "flex-start" : "center",
                    paddingInline: tAxis === "x" ? "0.5em" : "0.75em",
                    paddingBlock: "0.75em",
                  }}
                >
                  <p
                    className="text-white text-[0.625em] leading-none font-medium z-10 blur-0"
                  >
                    {title}
                  </p>
                  <div className="flex items-center gap-0.5 z-10">
                    {status === "ok" ? (
                      <TrendingOkIcon className="w-[1.25em] h-[1.25em] shrink-0" />
                    ) : (
                      <TrendingDownIcon className="w-[1.25em] h-[1.25em] shrink-0" />
                    )}
                    <span className="text-[1.5em] leading-none font-semibold text-white antialiased blur-0">
                      {value}
                    </span>
                  </div>
                  <svg
                    viewBox="0 0 37 32"
                    className="absolute top-0 left-0 w-full h-full blur-md"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                  >
                    <ellipse opacity="0.6" cx="17.8299" cy="27.1465" rx="7.91001" ry="4.31743" fill="#4D2F8A" />
                    <ellipse opacity="0.6" cx="17.8299" cy="4.58208" rx="7.91001" ry="4.31743" fill="#727272" />
                    <ellipse opacity="0.56" cx="31.2636" cy="18.3744" rx="5.60933" ry="4.766" fill="#FFFF00" />
                    <ellipse opacity="0.56" cx="2.42777" cy="22.6927" rx="1.66995" ry="5.83134" fill="#FFFF00" />
                  </svg>
                </div>
              </foreignObject>
            </g>
          )
        })}
      </svg>
    </div>
  )
}

export default HexGraph
