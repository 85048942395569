import _axios, { AxiosError, isAxiosError } from "axios"
import firebase from "config/firebase"
import { signOut } from "firebase/auth"

const axios = _axios.create({
  baseURL: process.env.NODE_ENV === "development"
    ? "http://localhost:5001/memeprophet-32ae6/us-central1/api"
    : "https://trenchscout.com/api",
})

axios.interceptors.request.use(async config => {
  const bearer = await firebase.auth.currentUser?.getIdToken()

  config.headers.setAuthorization(`Bearer ${bearer}`)

  return config
})

axios.interceptors.response.use(res => {
  return res
}, async(err: AxiosError<({ type?: string} & Record<string, unknown>) | string>) => {
  if (err.status === 401) {
    await signOut(firebase.auth)

    return
  } else if (err.status === 503) {
    throw err // custom error handling
  } else if (isAxiosError(err) && typeof err.response?.data !== "string" && err.response?.data.type) {
    throw err.response.data
  } else {
    throw err
  }
})

export default axios
