/* eslint-disable max-len */
import { Monkey1, Monkey2, Monkey3 } from "assets/monkeys"
import HexGraph from "components/HexGraph"
import { FC, useRef } from "react"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper/modules"
import "swiper/css"
import { LogoSrc } from "assets"
import { ArrowLeftItem, ArrowRightItem } from "assets/icons"

const samplePoints = [
  {
    value: 80,
    title: "Similarity Index",
    status: "ok" as const,
  },
  {
    value: 60,
    title: "Narrative",
    status: "trending-down" as const,
  },
  {
    value: 60,
    title: "Holders",
    status: "ok" as const,
  },
  {
    value: 73,
    title: "Uniqueness",
    status: "trending-down" as const,
  },
  {
    value: 95,
    title: "Price",
    status: "ok" as const,
  },
  {
    value: 80,
    title: "Sentiment",
    status: "ok" as const,
  },
]

const theBest = [{
  name: "Node.js",
  description: "12m Investments",
  image: Monkey1,
}, {
  name: "Django",
  description: "15m Investments",
  image: Monkey2,
}, {
  name: "Springboot",
  description: "5m Investments",
  image: Monkey3,
}]

const LandingPage: FC = () => {
  const swiper1Ref = useRef<SwiperRef>(null)
  const swiper2Ref = useRef<SwiperRef>(null)

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="px-5 pt-8 pb-12 flex flex-col gap-12 items-center lg:flex-row lg:pl-28 lg:pr-8 lg:pt-0 lg:items-start lg:gap-6 lg:h-full">
        <div className="flex flex-col items-center gap-6 text-center lg:items-start lg:text-left lg:w-[45.685%] lg:shrink-0 min-h-full">
          <img src={LogoSrc} className="h-11 lg:mb-7 lg:justify-self-start lg:mt-8 opacity-0 animate-[fade-in-top_0.5s_ease-in_2s_1_normal_forwards]" />
          <div className="flex flex-col items-center gap-6 text-center lg:items-start lg:text-left grow justify-center">
            <h1 className="text-6xl font-semibold text-white lg:text-[4.6875rem] opacity-0 animate-[fade-in-top_0.3s_ease-in_0.5s_1_normal_forwards]">
              Gain <span className="lg:block">Unfair</span> Advantage
            </h1>
            <h3 className="text-text-secondary text-base font-normal lg:w-[380px] lg:-mt-1 opacity-0 animate-[fade-in-top_0.3s_ease-in_1s_1_normal_forwards]">
              Uncover hidden patterns and analytics driving top memecoins with real-time predictions, powered by data science and AI
            </h3>
            <button
              className="text-text-dark font-semibold text-base align-middle lg:mt-3
           rounded-full py-5 px-11 leading-none bg-bg-green hover:brightness-95 transition-all
          opacity-0 animate-[fade-in-top_0.3s_ease-in_1.5s_1_normal_forwards] relative group"
            >
              Farm Points
              <div className="flex items-center justify-center absolute w-full h-full left-0 top-0 opacity-0 group-hover:opacity-100 transition-all z-10 backdrop-blur-md rounded-full">
                <span>
                  Coming Soon
                </span>
              </div>
            </button>
          </div>
          <div className="flex-col items-center w-[103%] hidden relative lg:hidden aspect-[611/172] -translate-x-[1.125rem] mt-2 opacity-0 animate-[fade-in-top_0.5s_ease-in_2s_1_normal_forwards]">
            <svg width="611" height="172" viewBox="0 0 611 172" className="absolute left-0 top-0 w-full h-full" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5H181C191.77 0.5 200.5 9.23045 200.5 20V28.5C200.5 39.8218 209.678 49 221 49H425.632C431.42 49 436.938 46.5531 440.825 42.2635L472.854 6.90792C476.551 2.82754 481.8 0.5 487.306 0.5H591C601.77 0.5 610.5 9.23045 610.5 20V152C610.5 162.77 601.77 171.5 591 171.5H20C9.23043 171.5 0.5 162.77 0.5 152V20Z" fill="#120A22" stroke="#382955" />
              <foreignObject width={148} height={22} x={20} y={20}>
                <span className="text-base text-white font-semibold h-4">
                  Backed by the Best
                </span>
              </foreignObject>
              <foreignObject width={92} height={36} x={499} y={16}>
                <div className="flex items-center justify-end gap-6">
                  <button
                    onClick={() => swiper1Ref.current?.swiper.slidePrev()}
                    className="w-9 h-9 rounded-full border border-[#382955] bg-[#120A22] hover:bg-[#382955] transition-colors
           flex items-center justify-center"
                  >
                    <ArrowLeftItem />
                  </button>
                  <button
                    onClick={() => swiper1Ref.current?.swiper.slideNext()}
                    className="w-9 h-9 rounded-full border border-[#382955] bg-[#120A22] hover:bg-[#382955] transition-colors
           flex items-center justify-center"
                  >
                    <ArrowRightItem />
                  </button>
                </div>
              </foreignObject>
              <foreignObject width={571} height={77} y={73} x={20}>
                <Swiper
                  ref={swiper1Ref}
                  className="w-full"
                  translate="yes"
                  spaceBetween={20}
                  navigation
                  slideToClickedSlide
                  centeredSlides={true}
                  draggable
                  slidesPerView={3}
                  initialSlide={1}
                  autoplay={theBest.length > 3 ? { delay: 2500 } : false}
                  onSlideChange={() => console.log("slide change")}
                  modules={[
                    Pagination,
                    Autoplay,
                  ]}
                >
                  {theBest.map(({ name, image, description }) => (
                    <SwiperSlide>
                      <div className="bg-[#120A22] py-4 px-3 rounded-xl flex items-center gap-2 border-[#382955] border overflow-hidden">
                        <div className="flex gap-2 items-center blur-sm select-none">
                          <img src={image} className="w-9 h-9 rounded-full object-cover" />
                          <div className="flex flex-col gap-1 text-white">
                            <span className="text-lg">
                              {name}
                            </span>
                            <span className="text-[0.5rem] leading-none">
                              {description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </foreignObject>
            </svg>
          </div>
        </div>
        <div className="w-[calc(100%-1rem)] lg:w-[49%] lg:py-6 opacity-0 animate-[fade-in-top_0.5s_ease-in_0.8s_1_normal_forwards]">
          <HexGraph
            dataPoints={samplePoints}
          />
        </div>
      </div>
      <div className="hidden flex-col items-center w-full lg:hidden opacity-0 animate-[fade-in-top_0.5s_ease-in_2s_1_normal_forwards]">
        <span className="text-base text-white font-semibold pb-6">
          Backed by the Best
        </span>
        <Swiper
          ref={swiper2Ref}
          className="w-full"
          centerInsufficientSlides
          translate="yes"
          spaceBetween={20}
          navigation
          slideToClickedSlide
          centeredSlides={true}
          draggable
          slidesPerView={2}
          initialSlide={1}
          autoplay={{ delay: 2500 }}
          onSlideChange={() => console.log("slide change")}
          modules={[
            Pagination,
            Autoplay,
          ]}
        >
          {theBest.map(({ name, image, description }) => (
            <SwiperSlide>
              <div className="bg-[#120A22] py-4 px-3 rounded-xl flex items-center gap-2 border-[#382955] border overflow-hidden">
                <div className="flex gap-2 items-center blur-sm select-none">
                  <img src={image} className="w-9 h-9 rounded-full object-cover" />
                  <div className="flex flex-col gap-1 text-white">
                    <span className="text-lg">
                      {name}
                    </span>
                    <span className="text-[0.75rem] leading-none">
                      {description}
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex w-full justify-center gap-6 py-8">
          <button
            onClick={() => swiper2Ref.current?.swiper.slidePrev()}
            className="w-9 h-9 rounded-full border border-[#382955] bg-[#120A22] hover:bg-[#382955] transition-colors
           flex items-center justify-center"
          >
            <ArrowLeftItem />
          </button>
          <button
            onClick={() => swiper2Ref.current?.swiper.slideNext()}
            className="w-9 h-9 rounded-full border border-[#382955] bg-[#120A22] hover:bg-[#382955] transition-colors
           flex items-center justify-center"
          >
            <ArrowRightItem />
          </button>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
