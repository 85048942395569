import authApi from "api/auth"
import Loader from "components/Loader"
import firebase from "config/firebase"
import { useAuth } from "contexts/Auth"
import { logEvent } from "firebase/analytics"
import { FC, useCallback, useEffect, useState } from "react"

const AuthorizeVSCodePage: FC = () => {
  const { user } = useAuth()
  const [submitting, setSubmitting] = useState(false)

  const authorizeExtension = useCallback(() => {
    if (submitting) return
    setSubmitting(true)
    logEvent(firebase.analytics, "extension_authorize")
    authApi.getToken().then(token => {
      window.postMessage({
        type: "AUTH_TOKEN",
        token,
      })
    })
  }, [submitting])

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data.type === "AUTH_SUCCESS") {
        setSubmitting(false)
        window.close()
      }
    }
    window.addEventListener("message", onMessage)

    return () => window.removeEventListener("message", onMessage)
  }, [])

  if (user === undefined) {
    return (
      <Loader />
    )
  }

  return (
    <div className="w-full h-full py-10 px-16">
      <div className="flex flex-col items-center justify-center h-full gap-6 grow bg-bg-secondary rounded-2.5 border border-border-primary">
        <h1 className="text-4xl font-semibold text-white">Authorize Extension</h1>
        {!user ? (
          <span className="text-text-secondary text-base">
            You need to connect your wallet to continue
          </span>
        ): (
          <>
            <span className="text-text-secondary text-base">
              Click below to authorize Extension
            </span>
            <button
              onClick={authorizeExtension}
              disabled={submitting}
              className="bg-white text-black hover:bg-slate-100 transition-colors text-base
              px-8 border border-transparent hover:border-black py-5 rounded-full"
            >
              Continue to Extension
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default AuthorizeVSCodePage
