import authApi from "api/auth"
import ChainPickerModal from "components/modals/ChainPicker"
import firebase from "config/firebase"
import { logEvent } from "firebase/analytics"
import { User, signInWithCustomToken } from "firebase/auth"
import { comeBack } from "helpers/redirects"
import useAwaitableModal from "hooks/useAwaitableModal"
import {
  PropsWithChildren,
  createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState,
} from "react"
import { useSearchParams } from "react-router-dom"

type AuthContextType = {
    user: User | null | undefined;
    signInWithEthereum: () => Promise<void>;
    signInWithPhantom: () => Promise<void>;
    authInProgress: boolean;
};

export const AuthContext = createContext({} as AuthContextType)

export const useAuth = () => useContext(AuthContext)

const AuthProvider = forwardRef<AuthContextType, PropsWithChildren>(({ children }, ref) => {
  const [user, setUser] = useState<User | null>()
  const [authInProgress, setAuthInProgress] = useState(false)
  const [sp, setSp] = useSearchParams()
  const [pickChain, ChainPickerComponent] = useAwaitableModal(ChainPickerModal, { chain: "", addresses: [] })

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        comeBack("authorize")
      }
      setUser(user)
      setAuthInProgress(false)
    })

    return () => unsubscribe()
  }, [])

  const authWithToken = useCallback((token: string) => {
    if (authInProgress) return
    setAuthInProgress(true)
    signInWithCustomToken(firebase.auth, token).catch(e => {
      console.error(e)
      setSp(old => {
        old.delete("token")

        return old
      })
      setAuthInProgress(false)
    })
  }, [authInProgress, setSp])

  const signInWithEthereum = useCallback(async() => {
    if (authInProgress) return
    setAuthInProgress(true)
    authApi.signInWithEthereum(pickChain).then(token => {
      authWithToken(token)
      setAuthInProgress(false)
    }).catch(e => {
      console.error(e)
      setAuthInProgress(false)
    })
  }, [authInProgress, authWithToken, pickChain])

  const signInWithPhantom = useCallback(async() => {
    if (authInProgress) return
    setAuthInProgress(true)
    authApi.signInWithPhantom().then(token => {
      logEvent(firebase.analytics, "login", { method: "solana" })
      authWithToken(token)
      setAuthInProgress(false)
    }).catch(e => {
      console.error(e)
      setAuthInProgress(false)
    })
  }, [authInProgress, authWithToken])

  useEffect(() => {
    const token = sp.get("token")
    if (token) {
      authWithToken(sp.get("token")!)
    }
  }, [sp, authWithToken])

  const value = useMemo(
    () => ({ user, signInWithEthereum, authInProgress, signInWithPhantom }),
    [user, signInWithEthereum, authInProgress, signInWithPhantom],
  )

  useImperativeHandle(ref, () => value, [value])

  return (
    <AuthContext.Provider value={value}>
      <ChainPickerComponent />
      {children}
    </AuthContext.Provider>
  )
})

export default AuthProvider

