import { AxiosInstance } from "axios"
import axios from "./axios"
import { IBiggestPump } from "types/pumps"

class PublicApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getBiggestPumps() {
    return this.axios.get("/public/biggest-pumps").then(res => res.data.pumps as IBiggestPump[])
  }
}

const publicApi = new PublicApi()

export default publicApi
